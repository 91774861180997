import { defineStore } from "pinia";
import {
    PINIA_PERSIST_OPTIONS,
    VoiceAccent,
    VoiceScript,
    getAccents,
    getCallFlows,
    getProfessionalGreetingsList,
} from "../helpers";
import { notify } from "@kyvg/vue3-notification";
import { useTeamStore } from "./team-member.store";
import emitter from "../helpers/integrations/emitter";

interface Schedule {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
}
interface CurrentSetCompanyGreeting {
    business_number: number;
    text: string;
    greeting_type: string;
    voice_accent: string;
    greeting_name: string;
    uuid: string;
}

interface CallConfigState {
    currentSetBusinessHours: Schedule | any;
    welcomeProfessionalGreetingList: VoiceScript[] | [];
    outofOfficeProfessionalGreetingList: VoiceScript[] | [];
    currentIncomingSetGreeting: CurrentSetCompanyGreeting | null;
    currentOutOfSetGreeting: CurrentSetCompanyGreeting | null;
    accents: VoiceAccent[] | null;
    currentStep: number;
    selectedTemplateIndex: number | null;
    isOutOfoffice: boolean;
    selectedVoiceAccent: string | null;
    selectedAccent: string | null;
    greetingActivatingProgress: number;
    isPublishingAudio: boolean;
    isPublished: boolean;
    teamMembers?: any;
    entries:
        | any
        | Record<
              string,
              { id: string; digit: string; kind: string; param: number }
          >;
    allCallFlow: any;
    isGettingFlow: boolean;
    flowToEdit: any;
    editFlow: boolean;
    requestPayload: {
        name: string;
        selectedIncomingTemplate: string;
        selectedOutOfOfficeTemplate: string;
        call_forwarding_line: string | null | undefined;
        selectedForward: string;
        selectedRingRule: string;
    };
    getCurrentWelcomeAudio: null | any;
    getCurrentOutOfOfficeAudio: null | any;
    isGeneratingWelcomeAudio: boolean;
    hasGeneratedWelcomeAudio: boolean;
    isGeneratingOutOfOfficeAudio: boolean;
    hasGeneratedOutOfOfficeAudio: boolean;
    isCompletingSetupFlow: boolean;
    assignNumberForFirstFlow: boolean;
    teamMemberSelection: any;
    applyIvr: boolean;
    listOfTeamsMemberToUpdate: any;
    flowToAssignAfterCreate: string | null;
    showTemplateNav: boolean;
    newGreeting: string;
    newOOFGreeting: string;
}

export const useCallConfigStore = defineStore({
    id: "call-config",
    state: (): CallConfigState => ({
        currentSetBusinessHours: {},
        welcomeProfessionalGreetingList: [],
        outofOfficeProfessionalGreetingList: [],
        currentIncomingSetGreeting: null, // used later to filter greeting
        currentOutOfSetGreeting: null, // used later to filter greeting
        accents: null,
        currentStep: 0,
        selectedTemplateIndex: null,
        selectedAccent: null, // country accent
        selectedVoiceAccent: null,
        isOutOfoffice: false, // use to toggle the greeting sample template rendered
        greetingActivatingProgress: 0,
        isPublishingAudio: false,
        isPublished: false,
        entries: {},
        teamMembers: [],
        allCallFlow: [], // all the call config after fetch
        isGettingFlow: false,
        flowToEdit: {},
        editFlow: true,
        requestPayload: {
            name: "",
            selectedIncomingTemplate: "",
            selectedOutOfOfficeTemplate: "",
            call_forwarding_line: useTeamStore().teamMembers?.find(
                (item) => item?.role === "owner"
            )?.mobile, // get owner number to set call forwarding line
            selectedForward: "callForwarding",
            selectedRingRule: "simultaneous",
        },
        getCurrentWelcomeAudio: null,
        getCurrentOutOfOfficeAudio: null,
        isGeneratingWelcomeAudio: false,
        hasGeneratedWelcomeAudio: false,
        isGeneratingOutOfOfficeAudio: false,
        hasGeneratedOutOfOfficeAudio: false,
        isCompletingSetupFlow: false,
        assignNumberForFirstFlow: false,
        teamMemberSelection: [
            ...useTeamStore().teamMembers?.sort((a, b) => a?.user - b?.user),
        ],
        applyIvr: false,
        listOfTeamsMemberToUpdate: [],
        flowToAssignAfterCreate: null,
        showTemplateNav: false,
        newGreeting: "existing",
        newOOFGreeting: "existing",
    }),
    getters: {
        getCurrentSetGreetingAccentInfo(state) {
            if (
                state.welcomeProfessionalGreetingList &&
                state.currentIncomingSetGreeting
            ) {
                return state.accents?.find(
                    (greeting) =>
                        greeting.voice_id ===
                        state.currentIncomingSetGreeting?.voice_accent
                );
            }
        },
        getCurrentSetOutOfOfficeAccentInfo(state) {
            if (
                state.outofOfficeProfessionalGreetingList &&
                state.currentOutOfSetGreeting
            ) {
                return state.accents?.find(
                    (greeting) =>
                        greeting.voice_id ===
                        state.currentOutOfSetGreeting?.voice_accent
                );
            }
        },
        getRingRuleOrder(): any {
            // arrange the user id for ring group based on order
            if (this.teamMemberSelection) {
                return this.teamMemberSelection.map((item: any) => item?.user);
            } else return [];
        },
        isRingGroup(): boolean {
            return !this.applyIvr;
        },
        isEveryDateMatching(): boolean {
            // checks if everyday matches format beolow. this means business hours was never set
            const expectedSchedule = {
                sunday: "00:00-23:59",
                monday: "00:00-23:59",
                tuesday: "00:00-23:59",
                wednesday: "00:00-23:59",
                thursday: "00:00-23:59",
                friday: "00:00-23:59",
                saturday: "00:00-23:59",
            } as any;

            for (const day in expectedSchedule) {
                if (
                    (Object.keys(this.currentSetBusinessHours).length > 0 &&
                        !this.currentSetBusinessHours.hasOwnProperty(day)) ||
                    this.currentSetBusinessHours[day] !== expectedSchedule[day]
                ) {
                    return false;
                }
            }

            return true;
        },
    },
    actions: {
        async getGreetingsByType(prompt: string) {
            try {
                const greetings = await getProfessionalGreetingsList(prompt);
                if (prompt === "WELCOME_PROMPT")
                    this.welcomeProfessionalGreetingList = greetings?.data;
                if (prompt === "OUT_OF_OFFICE_PROMPT")
                    this.outofOfficeProfessionalGreetingList = greetings?.data;
            } catch (error) {}
        },
        async getAccentLists() {
            try {
                const accents = await getAccents();
                this.accents = accents;
            } catch (error) {}
        },
        populateInput(item: string, index: number) {
            this.selectedTemplateIndex = index;
            if (!this.isOutOfoffice)
                this.requestPayload.selectedIncomingTemplate = item;
            else this.requestPayload.selectedOutOfOfficeTemplate = item;
        },
        clearStore() {
            this.entries = {};
            this.currentStep = 0;
            this.isPublished = false;
            this.isPublishingAudio = false;
            this.currentSetBusinessHours = {};
            this.isCompletingSetupFlow = false;
            this.hasGeneratedOutOfOfficeAudio = false;
            this.isGeneratingOutOfOfficeAudio = false;
            this.hasGeneratedWelcomeAudio = false;
            this.isGeneratingWelcomeAudio = false;
            this.requestPayload = {
                name: "",
                selectedIncomingTemplate: "",
                selectedOutOfOfficeTemplate: "",
                call_forwarding_line: useTeamStore().teamMembers?.find(
                    (item) => item?.role === "owner"
                )?.mobile,
                selectedForward: "callForwarding",
                selectedRingRule: "simultaneous",
            };
            this.editFlow = false;
            this.currentIncomingSetGreeting = null;
            this.getCurrentOutOfOfficeAudio = null;
            this.getCurrentWelcomeAudio = null;
            this.newGreeting = "existing";
            this.newOOFGreeting = "existing";
            this.teamMembers = [];
            this.teamMemberSelection = [...useTeamStore().teamMembers].sort(
                (a: any, b: any) => a?.user - b?.user
            );
            this.applyIvr = false;
            // sort to prevent js from reaarange the route number
        },
        async getIncomingFlow(link?: string) {
            try {
                this.allCallFlow = []; // clears store if cached there
                this.isGettingFlow = true;
                const response = await getCallFlows(link);
                this.allCallFlow = response?.data;
                this.isGettingFlow = false;
            } catch (error) {
                this.isGettingFlow = false;
                notify({
                    text: `${error}`,
                });
            }
        },
        async handleSubmit() {
            emitter.emit("completeSetup");
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
