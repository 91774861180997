// import { CallScript } from "./../helpers/queries/callScript";
// src/router/index.ts
import {
    createRouter,
    createWebHistory,
    RouteRecordRaw,
    RouteLocationNormalized,
} from "vue-router";
import {
    useAuthStore,
    useNumberStore,
    useUsersStore,
    useAddonStore,
    useBusinessStore,
    useLiveCallWidgetStore,
} from "../stores";

import openReplay from "../helpers/openReplay";
import { notify } from "@kyvg/vue3-notification";
import DeveloperProfile from "../modules/developer-program/views/DeveloperProfile.vue";
import ConnectionView from "../modules/developer-program/views/ConnectionView.vue";
import WelcomePage from "../modules/developer-program/views/WelcomePage.vue";
const Dashboard = () => import("../views/DashboardView.vue");
const Contact = () => import("../views/ContactView.vue");
const ManagerCallRecords = () =>
    import("../modules/callRecords/RecordsView.vue");
const ManagerContact = () => import("../modules/contact/ManagerContact.vue");
const Signin = () => import("../views/SigninView.vue");
const Otp = () => import("../views/OtpView.vue");
const CallRecords = () => import("../modules/callRecords/AgentCallRecords.vue");
const Profile = () => import("../modules/userProfile/AgentProfile.vue");
const Reports = () => import("../views/ReportsView.vue");
const Report = () => import("../views/report/index.vue");
const BillingHome = () => import("../views/billing/index.vue");
const Billing = () => import("../views/billing/Billing.vue");
const Activities = () => import("../views/ActivityView.vue");
const Signup = () => import("../views/SignupView.vue");
const CompleteSignup = () => import("../views/CompleteSignupView.vue");
const AgentSignUp = () => import("../views/AgentSignupView.vue");
const Integrations = () =>
    import("../modules/integrations/IntegrationsView.vue");
const DynamicView1 = () =>
    import("../modules/integrations/views/dynamicView1.vue");
const ChromeExtension = () =>
    import("../modules/integrations/views/ChromeExtension.vue");
const ZohoPhoneBridge = () =>
    import("../modules/integrations/views/ZohoPhonebridge.vue");
const Zapier = () => import("../modules/integrations/views/Zapier.vue");
// const PressoneWidget = () =>
//     import("../modules/integrations/views/PressoneWidget.vue");
const IntegrationsList = () =>
    import("../modules/integrations/IntegrationsList.vue");

const CallBack = () => import("../components/CallBack.vue");

// const Activities = () => import("../views/ActivityView.vue");
const UserOnboardingStatus = () =>
    import("../modules/personalization/views/UserOnboardingStatusView.vue");
const InitialUserOnboardingView = () =>
    import(
        "../modules/personalization/views/UserOnboardingStatusInitialView.vue"
    );
const OutStandingPaymentPage = () =>
    import("../views/OutstandingPaymentPage.vue");
const NumberProvision = () =>
    import("../modules/numberProvisioning/NumberProvisionView.vue");

const AdditionalPurchase = () =>
    import(
        "../modules/numberProvisioning/AdditionalNumberPurchase/AdditionalNumber.vue"
    );

// const NumberProvision = () => import("../views/ManagerPortal/NumberProvisionView.vue");
// const ActivateNumber = () => import("../views/ManagerPortal/ActivateNumberView.vue");

const TeamView = () => import("../modules/teamMembers/view/TeamView.vue");
const ManageTags = () => import("../modules/tags/view/ManageTags.vue");
const ManagersProfile = () =>
    import("../modules/userProfile/ManagerProfile.vue");
const LazyAffiliateView = () => import("../views/AffiliateView.vue");
// Personalization
const AddNewCallFlow = () =>
    import("../modules/personalization/views/AddNewCallFlow.vue");
const IncomingCallFlow = () =>
    import("../modules/personalization/views/IncomingCallFlow.vue");
// const CustomerEngagement = () => import("../views/CustomerEngagement.vue");
// const AgentCustomerEngagement = () =>
//     import("../views/AgentCustomerEngagement.vue");

const CallScript = () => import("../modules/callScript/CallScriptView.vue");
const Connection = () => import("../modules/connection/ConnectionView.vue");
const ConnectionManager = () =>
    import("../modules/connection/ConnectionView.vue");
const VideoDemo = () => import("../modules/video-demos/VideoDemoView.vue");
const AddOnList = () => import("../modules/add-ons/index.vue");
const AddOns = () => import("../modules/add-ons/components/AddOnLayout.vue");
const KnowledgeDomainAddon = () =>
    import("../modules/add-ons/knowledge-base/KnowledgeBase.vue");
const VoiceOtpAddon = () =>
    import("../modules/add-ons/voice-otp/views/VoiceOtp.vue");
const TextToSpeechAddon = () =>
    import("../modules/add-ons/text-to-speech/views/TextToSpeech.vue");

const LiveCallOnly = () =>
    import(
        "../modules/add-ons/live-call-widget/views/live-call-only/LiveCallOnlyView.vue"
    );
const UpgradeSubscribe = () =>
    import(
        "../modules/add-ons/live-call-widget/views/live-call-only/UpgradeSubscribe.vue"
    );
const LiveCallOnlyRegistration = () =>
    import(
        "../modules/add-ons/live-call-widget/views/live-call-only/Registeration.vue"
    );
const LiveCallWidget = () =>
    import("../modules/add-ons/live-call-widget/views/LiveCallWidget.vue");

const LiveCallWelcome = () =>
    import(
        "../modules/add-ons/live-call-widget/views/live-call-only/Welcome.vue"
    );
const AddOnOnlyLayout = () =>
    import("../modules/add-ons/components/AddOnOnlyLayout.vue");

// Developer Program Layout
const DeveloperProgram = () => import("../layouts/DeveloperProgramLayout.vue");
const DeveloperDashboard = () =>
    import("../modules/developer-program/views/Dashboard.vue");
// const AddonCollections = () =>
//     import("../modules/developer-program/views/AddOn.vue");

// Developer Program Layout
const PostOnboarding = () =>
    import("../modules/post-onboarding/view/ManagerOnboarding.vue");

const AgentPostOnboarding = () =>
    import("../modules/post-onboarding/view/AgentOnboarding.vue");

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/agents/contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/managers/contact",
        name: "ManagerContact",
        component: ManagerContact,
    },
    {
        path: "/sign-in",
        name: "Signin",
        component: Signin,
        // beforeEnter: () => {
        //   const queryParams = new URLSearchParams(window.location.search);
        //   const utmSource = queryParams.get("utm_source");
        //   if (utmSource) {
        //     return "/sign-up" + "?utm_source=" + utmSource;
        //   }
        // },
    },
    {
        path: "/outstanding-invoice-payment",
        name: "OutStandingPaymentPage",
        component: OutStandingPaymentPage,
    },
    {
        path: "/callback/:integrationSource",
        name: "CallBack",
        component: CallBack,
    },

    {
        path: "/sign-up",
        name: "Signup",
        component: Signup,
    },
    {
        path: "/complete-signup",
        name: "CompleteSignup",
        component: CompleteSignup,
    },
    {
        path: "/otp",
        name: "Otp",
        component: Otp,
    },
    {
        path: "/home",
        redirect: (to) => {
            if (to.query.trxref && to.query.reference) {
                return "/otp";
            }
            return "/";
        },
    },
    {
        path: "/agents/call-records",
        name: "Call Records",
        component: CallRecords,
    },
    {
        path: "/agents/profile-update",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/agents/connection-status",
        name: "Connection",
        component: Connection,
    },
    {
        path: "/agents/reports",
        name: "Reports",
        component: Reports,
    },
    {
        path: "/agents/demo-videos",
        name: "AgentVideoDemo",
        component: VideoDemo,
    },
    {
        path: "/agent/signup",
        name: "AgentSignUp",
        component: AgentSignUp,
    },
    {
        path: "/managers/billing",
        name: "Billing",
        component: BillingHome,
    },

    {
        path: "/managers/billing/:id/:plan",
        name: "Billing Details",
        component: Billing,
    },

    {
        path: "/managers/reports",
        name: "Report",
        component: Report,
    },

    {
        path: "/managers/activities",
        name: "Activities",
        component: Activities,
    },
    {
        path: "/managers/call-records",
        name: "ManagerCallRecords",
        component: ManagerCallRecords,
    },
    {
        path: "/managers/provision-number",
        name: "NumberProvision",
        component: NumberProvision,
    },
    {
        path: "/managers/additional-purchase",
        name: "AdditionalPurchase",
        component: AdditionalPurchase,
    },
    {
        path: "/managers/user-onboarding-status",
        name: "UserOnboardingStatus",
        component: UserOnboardingStatus,
    },
    // {
    //   path: "/provision-number",
    //   name: "NumberProvision",
    //   component: NumberProvision,
    // },

    {
        path: "/callback/:integrationSource",
        name: "CallBack",
        component: CallBack,
    },
    {
        path: "/sign-up",
        name: "Signup",
        component: Signup,
    },
    {
        path: "/complete-signup",
        name: "CompleteSignup",
        component: CompleteSignup,
    },
    {
        path: "/otp",
        name: "Otp",
        component: Otp,
    },
    {
        path: "/agents/call-records",
        name: "Call Records",
        component: CallRecords,
    },
    {
        path: "/agents/profile-update",
        name: "Profile",
        component: Profile,
    },
    {
        path: "/agents/connection-status",
        name: "Connection",
        component: Connection,
    },
    {
        path: "/managers/demo-videos",
        name: "VideoDemo",
        component: VideoDemo,
    },
    {
        path: "/agents/reports",
        name: "Reports",
        component: Reports,
    },
    // {
    //     path: "/agents/engagement",
    //     name: "Agent Customer Engagement",
    //     component: AgentCustomerEngagement,
    // },
    {
        path: "/agents/engagement",
        name: "AgentPostOnboarding",
        component: AgentPostOnboarding,
    },

    {
        path: "/agent/signup",
        name: "AgentSignUp",
        component: AgentSignUp,
    },
    {
        path: "/managers/billing",
        name: "Billing",
        component: BillingHome,
    },
    // {
    //     path: "/managers/engagement",
    //     name: "Customer Engagement",
    //     component: CustomerEngagement,
    // },
    {
        path: "/managers/engagement",
        name: "PostOnboarding",
        component: PostOnboarding,
    },

    {
        path: "/managers/billing/:id/:plan",
        name: "Billing Details",
        component: Billing,
    },
    {
        path: "/managers/reports",
        name: "Report",
        component: Report,
    },
    {
        path: "/managers/activities",
        name: "Activities",
        component: Activities,
    },
    {
        path: "/managers/provision-number",
        name: "NumberProvision",
        component: NumberProvision,
    },
    {
        path: "/managers/additional-purchase",
        name: "AdditionalPurchase",
        component: AdditionalPurchase,
    },
    {
        path: "/managers/user-onboarding-status",
        name: "UserOnboardingStatus",
        component: UserOnboardingStatus,
    },
    {
        path: "/managers/initial-user-onboarding",
        name: "InitialUserOnboardingView",
        component: InitialUserOnboardingView,
    },
    // {
    //   path: "/provision-number",
    //   name: "NumberProvision",
    //   component: NumberProvision,
    // },
    {
        path: "/managers/team",
        name: "TeamView",
        component: TeamView,
    },
    {
        path: "/managers/manage-tags",
        name: "ManageTags",
        component: ManageTags,
    },
    {
        path: "/managers/profile-update",
        name: "ManagersProfile",
        component: ManagersProfile,
    },
    // Personalization
    {
        path: "/managers/incoming-flow",
        name: "IncomingCallFlow",
        component: IncomingCallFlow,
    },
    {
        path: "/managers/call-script",
        name: "CallScript",
        component: CallScript,
    },
    {
        path: "/managers/add-new-call-flow",
        name: "AddNewCallFlow",
        component: AddNewCallFlow,
    },
    {
        path: "/affiliate",
        name: "AffiliateView",
        component: LazyAffiliateView,
    },
    {
        path: "/managers/connection-status",
        name: "ConnectionManager",
        component: ConnectionManager,
    },
    {
        path: "/welcome",
        name: "WelcomePage",
        component: WelcomePage,
    },

    {
        path: "/managers/integrations",
        name: "Integrations",
        component: Integrations,
        children: [
            {
                path: "",
                name: "IntegrationsList",
                component: IntegrationsList,
            },
            {
                path: "chrome-extension",
                name: "ChromeExtension",
                component: ChromeExtension,
            },
            {
                path: "zoho-phonebridge",
                name: "ZohoPhoneBridge",
                component: ZohoPhoneBridge,
            },
            {
                path: "zapier",
                name: "Zapier",
                component: Zapier,
            },
            // {
            //     path: "pressone-widget",
            //     name: "PressoneWidget",
            //     component: PressoneWidget,
            // },
            {
                path: ":id",
                name: "DynamicView1",
                component: DynamicView1,
                beforeEnter: (to) => {
                    const allowedIntegrationRoutes = [
                        "hubspot-crm",
                        "intercom",
                        "pipe-drive",
                        "fresh-desk",
                        "zoho-crm",
                    ];
                    const params = to?.params?.id as string;
                    if (!allowedIntegrationRoutes.includes(params)) {
                        return false;
                    }
                    return;
                },
            },
        ],
    },
    {
        path: "/managers/add-ons",
        name: "AddOns",
        component: AddOns,
        children: [
            {
                path: "list",
                name: "AddOns",
                component: AddOnList,
            },
            {
                path: "knowledge-base",
                name: "knowledgeBaseAddon",
                component: KnowledgeDomainAddon,
            },
            {
                path: "live-call-widget",
                name: "LiveCallWidget",
                component: LiveCallWidget,
            },
            {
                path: "voice-otp",
                name: "VoiceOtpAddon",
                component: VoiceOtpAddon,
            },
            {
                path: "text-to-speech",
                name: "TextToSpeechAddon",
                component: TextToSpeechAddon,
            },
        ],
    },
    // Addon only and free trial layout

    // Addon only and free trial layout same layout may be used for subsequent free trial add-ons
    {
        path: "/add-on",
        name: "AddOnOnlyLayout",
        component: AddOnOnlyLayout,
        children: [
            {
                path: "free-live-call",
                name: "LiveCallOnly",
                component: LiveCallOnly,
            },

            {
                path: "live-call-free-widget/register-website",
                name: "LiveCallOnlyRegistration",
                component: LiveCallOnlyRegistration,
            },
            {
                path: "live-call-free-widget/welcome",
                name: "LiveCallWelcome",
                component: LiveCallWelcome,
            },
            {
                path: "live-call-free-widget/upgrade-subscription",
                name: "UpgradeSubscribe",
                component: UpgradeSubscribe,
            },
        ],
    },
    // Addon only and free trial layout

    // Start Developer Program routes
    {
        path: "/developers",
        name: "DeveloperProgram",
        component: DeveloperProgram,
        children: [
            {
                path: "dashboard",
                name: "DeveloperDashboard",
                component: DeveloperDashboard,
            },
            {
                path: "live-call-widget",
                name: "DeveloperLiveCallWidget",
                component: LiveCallWidget,
            },
            // Start Developer Program routes
            {
                path: "voice-otp",
                name: "DeveloperVoiceOtpAddon",
                component: VoiceOtpAddon,
            },
            // Start Developer Program routes
            {
                path: "profile-update",
                name: "DeveloperProfile",
                component: DeveloperProfile,
            },
            {
                path: "live-call-free-widget/upgrade-subscription",
                name: "DevUpgradeSubscribe",
                component: UpgradeSubscribe,
            },
            {
                path: "connection",
                name: "Connections",
                component: ConnectionView,
            },
        ],
    },

    // End Developer Program routes

    { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to: RouteLocationNormalized) => {
    // redirect to login page if not logged in and trying to access a restricted page

    const paramsPath = to.fullPath.split("?");
    if (paramsPath.length > 0) {
        const params = new URLSearchParams(paramsPath[1]);
        // Display notification
        if (
            params.get("trxref") &&
            params.get("reference") &&
            !params.get("redirected") // add this to check if it has redirected before to prevent infinite loop.
        ) {
            notify({
                text: "Payment successful",
                type: "success",
            });

            const authStore = useAuthStore();
            authStore.couponCode = "";
            params.set("redirected", "true"); // set to true to prevent it from redirecting in a loop
            return `/otp?${params.toString()}`; // redirect to otp from here
        }
    }

    const publicPages = [
        "/sign-in",
        "/sign-up",
        "/otp",
        "/complete-signup",
        "/agent/signup",
        "/affiliate",
        "/welcome",
        // "/outstanding-invoice-payment",
    ];

    // This are the allowed route that a freetrial user can access
    const freeTrialRoutes = [
        "/welcome",
        "/add-on/free-live-call",
        "/add-on/live-call-free-widget/register-website",
        "/add-on/live-call-free-widget/welcome",
        "/add-on/live-call-free-widget/upgrade-subscription",
        "/managers/provision-number",
        "/managers/add-ons/live-call-widget",
        "/managers/profile-update",
        "/developers/dashboard",
        "/developers/profile-update",
        "/developers/live-call-free-widget/upgrade-subscription",
        "/developers/live-call-widget",
        "/developers/voice-otp",
        "/developers/connection",
        "/add-on/live-call-free-widget/upgrade-subscription",
        "/managers/provision-number", // can access this when user wants to upgrade
    ];

    const authRequired = !publicPages.includes(to.path);

    const authStore = useAuthStore();
    const userStore = useUsersStore();
    const numberStore = useNumberStore();
    const addonStore = useAddonStore();
    const businessStore = useBusinessStore();
    const liveCallWidgetStore = useLiveCallWidgetStore();

    // Check if token exists in local storage
    const token = localStorage.getItem("token");

    if (token && !authStore.isAuthenticated) {
        authStore.token = token;
    }

    if (to.fullPath.includes("/outstanding-invoice-payment")) {
        return;
    }

    if (authRequired && !authStore.isAuthenticated) {
        authStore.returnUrl = to.fullPath;
        return "/sign-in";
    }

    if (authStore.isAuthenticated && publicPages.includes(to.path)) {
        if (to.path != "/complete-signup" && to.path != "/welcome") {
            if (
                userStore.user_role == "owner" ||
                userStore.user_role == "manager"
            ) {
                return "/managers/activities";
            }
            return "/";
        }
    }

    if (
        to.path == "/" &&
        (userStore.user_role == "owner" || userStore.user_role == "manager")
    ) {
        return "/managers/activities";
    }
    if (to.fullPath.includes("/managers/")) {
        if (
            userStore.user_role != "owner" &&
            userStore.user_role != "manager"
        ) {
            return "/";
        }
    }

    if (to.fullPath.includes("/agents/")) {
        if (
            userStore.user_role == "owner" ||
            userStore.user_role == "manager"
        ) {
            return "/managers/activities";
        }
    }

    // restriction for users trying to access the live call page with validation
    if (to.fullPath.includes("/live-call-widget")) {
        if (
            liveCallWidgetStore.hasAccess &&
            businessStore.activeProfile.business.free_trial_data
                .free_trial_expired
        ) {
            const sourceRoute = userStore.currentUserBusinessLevel
                ?.free_trial_data?.is_developer
                ? "/developers/live-call-free-widget/"
                : "/add-on/live-call-free-widget/";
            return `${sourceRoute}upgrade-subscription`;
        }

        if (
            !liveCallWidgetStore.hasAccess &&
            !userStore.currentUserBusinessLevel?.free_trial_data
                ?.is_free_trial_user // redirect this user to dashboard to main page if no access and no free trial
        ) {
            return "/managers/activities";
        }
    }
    // NB if a free trial user tries to access a path not allowed
    if (
        userStore.currentUserBusinessLevel?.free_trial_data
            ?.is_free_trial_user &&
        !freeTrialRoutes.includes(to.path)
    ) {
        const sourceRoute = userStore.currentUserBusinessLevel?.free_trial_data
            ?.is_developer
            ? "/developers/dashboard"
            : "/managers/add-ons/live-call-widget";
        return sourceRoute;
    }

    if (to.fullPath.includes("/knowledge-base")) {
        const hasAccess = addonStore.getAllEnhancedAddons.some(
            (addon) =>
                addon.title.includes("Knowledge Base Addon") &&
                addon.isPurchased
        );

        if (!hasAccess) {
            return "/manager/activities";
        }
    }
    if (to.fullPath.includes("/voice-otp")) {
        const hasAccess = addonStore.getAllEnhancedAddons.some(
            (addon) =>
                (addon.title.includes("Voice OTP Addon") &&
                    addon.isPurchased) ||
                userStore.currentUserBusinessLevel?.free_trial_data
                    .is_free_trial_user
        );

        if (!hasAccess) {
            return "/manager/activities";
        }
    }
    if (to.fullPath.includes("/text-to-speech")) {
        const hasAccess = addonStore.getAllEnhancedAddons.some(
            (addon) =>
                (addon.title.includes("Text To Speech Addon") &&
                    addon.isPurchased) ||
                userStore.currentUserBusinessLevel?.free_trial_data
                    .is_free_trial_user
        );

        if (!hasAccess) {
            return "/manager/activities";
        }
    }

    // openReplay.start();
    // Permissions redirection // hide the module from the sidebar but also redirect for users that want to bypass it
    if (to.fullPath.includes("agents/reports")) {
        /// redirects when isnt owner or permissions isnt true
        if (!userStore.getAgentRole.can_view_activity_report) {
            return "/";
        }
    }
    // Managers
    if (to.fullPath.includes("managers/user-onboarding-status")) {
        /// redirects when isnt owner or permissions isnt true
        if (!userStore.getAgentRole.can_manage_personalization) {
            return "/managers/activities";
        }
    }
    if (to.fullPath.includes("managers/billing")) {
        /// redirects whenpermissions isnt true
        if (!userStore.getAgentRole.can_manage_billing) {
            return "/managers/activities";
        }
    }

    if (
        to.fullPath.includes("managers/integrations") ||
        to.fullPath.includes("callback")
    ) {
        /// redirects when user not allowed to view integrations
        if (!numberStore.showIntegration) {
            return "/managers/activities";
        }
    }
    if (
        to.fullPath.includes("managers/manage-tags") ||
        to.fullPath.includes("managers/incoming-flow") ||
        to.fullPath.includes("managers/call-script")
    ) {
        /// redirects when  permissions isnt true
        if (!userStore.getAgentRole.can_manage_personalization) {
            return "/managers/activities";
        }
    }

    if (to.fullPath.includes("agents/engagement")) {
        /// redirects when this form has been filled to main page
        if (userStore.currentUserBusinessLevel?.has_filled_engagement_form) {
            return "/agents/dashboard";
        }
    }
    if (import.meta.env.MODE !== "development") {
        if (userStore.currentUserBusinessLevel) {
            openReplay.setUserID(
                `${userStore.currentUserBusinessLevel?.personal_email}`
            );
        }
        openReplay.start();
    }
    // Permissions redirection
    // hide the module from the sidebar but also redirect for users that want to bypass it
});

// router.onError(() => {
//     location.reload();
// });

export default router;
